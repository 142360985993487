import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../package.json";
import { ImageCropper } from "../src/index";
import ImageCropperExample from "./ImageCropperExample";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Metadata = makeShortcode("Metadata");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "image-cropper"
    }}>{`Image cropper`}</h1>
    <Metadata pkg={pkg} mdxType="Metadata" />
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <ImageCropperExample mdxType="ImageCropperExample" />
    <pre><code parentName="pre" {...{}}>{`import { ImageCropper } from "@happeokit/image-cropper";

render() {
    return (
        <ImageCropper
            url={this.state.url}
            viewport={{
              width: 200,
              height: 200,
              isCircle: true
            }}
        />
    )
}
`}</code></pre>
    <h2 {...{
      "id": "getting-the-result"
    }}>{`Getting the result`}</h2>
    <p>{`Emit an event called 'getResult' using the `}<inlineCode parentName="p">{`cropperEvent`}</inlineCode>{` from the package to trigger the result from the cropper. Simply pass a callback to `}<inlineCode parentName="p">{`onSave`}</inlineCode>{`
to get the result.`}</p>
    <pre><code parentName="pre" {...{}}>{`import { cropperEvent } from "@happeokit/image-cropper";

onSave = resultData => {
    this.closeModal();
    this.setState({ imageSrc: resultData });
};

getResult = () => {
    cropperEvent.emit('getResult');
}
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={ImageCropper} mdxType="Props" />
    <p><inlineCode parentName="p">{`boundary`}</inlineCode></p>
    <p>{`The outer container of the cropper.`}</p>
    <p><inlineCode parentName="p">{`viewport`}</inlineCode></p>
    <p>{`The inner container of the coppie. The visible part of the image. You can either set fixed width and height,
or specify the `}<inlineCode parentName="p">{`aspectRatio`}</inlineCode>{` with one of the dimensions. `}<inlineCode parentName="p">{`isCircle`}</inlineCode>{` property accepts boolean value, and can be omitted.`}</p>
    <pre><code parentName="pre" {...{}}>{`viewport={{
    aspectRatio: {{ w: 4, h: 3}},
    width: 200,
}}
`}</code></pre>
    <p><inlineCode parentName="p">{`result`}</inlineCode></p>
    <p>{`Configuration for the resulting crop of the image.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{`: The type of result to return defaults to `}<em parentName="li">{`'canvas'`}</em>{`. Valid values are `}<em parentName="li">{`'base64'`}</em>{`, `}<em parentName="li">{`'html'`}</em>{`, `}<em parentName="li">{`'blob'`}</em>{` and `}<em parentName="li">{`'rawcanvas'`}</em>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`size`}</inlineCode>{`: The size of the cropped image defaults to `}<em parentName="li">{`'viewport'`}</em>{`. Other values are `}<em parentName="li">{`'original'`}</em>{` or `}<em parentName="li">{`{ width, height }`}</em>{` object.
If only one dimension is specified, the other will be calculated using the viewport aspect ratio.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`format`}</inlineCode>{`: Indicating the image format. `}<em parentName="li">{`'png'`}</em>{`, `}<em parentName="li">{`'jpeg'`}</em>{` or `}<em parentName="li">{`'webp'`}</em>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`quality`}</inlineCode>{`: Number between 0 and 1 indicating image quality.`}
        <br />
        <br />
      </li>
    </ul>
    <p><inlineCode parentName="p">{`enableResize`}</inlineCode></p>
    <p>{`Enable or disable support for resizing the viewport area.`}</p>
    <p><inlineCode parentName="p">{`mouseWheelZoom`}</inlineCode></p>
    <p>{`Enable or disable the ability to use the mouse wheel to zoom in and out on a croppie instance.
If `}<em parentName="p">{`'ctrl'`}</em>{` is passed mouse wheel will only work while control keyboard is pressed`}</p>
    <p><inlineCode parentName="p">{`onSave`}</inlineCode></p>
    <p>{`Callback excuted when getting the result. Receive `}<inlineCode parentName="p">{`data`}</inlineCode>{` of the resulting image as the parameter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      